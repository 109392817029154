import Link from 'next/link'
import dynamic from 'next/dynamic'
const CustomImage = dynamic(() => import('./CustomImage'));
// import CustomImage from './CustomImage';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'

export default function Footer() {
    return (
        <footer id="footer">
            <div className="footer-grid">
                <div className="footer-branding text-white">
                    <div className="logo mb-2">
                        <CustomImage srcSetPaths={[{path: '/brand/logo.png?tr=w-120,f-avif', type: 'image/avif'},{path: '/brand/logo.png?tr=w-120,f-auto', type: 'image/webp'}]}
                            mainSrc="/brand/logo.png?tr=w-120,f-png" alt="Collegify Logo" width="120px" height="42px" imgCDN />
                    </div>
                </div>
                <div className="footer-links-1 text-white">
                    <Link href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI + "/testimonials"}><a><h5 className="footer-link">Testimonials</h5></a></Link>
                    <Link href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI + "/hallOffame"}><a><h5 className="footer-link">Hall of Fame</h5></a></Link>
                    <a href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI + "/blog"}><h5 className="footer-link">Blog</h5></a>
                    <a href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI + "/careers"}><h5 className="footer-link">Careers</h5></a>
                </div>
                <div className="footer-links-2 text-white">
                    <Link href="/undergraduate"><a><h5 className="footer-link">UG</h5></a></Link>
                    <Link href="/postgraduate"><a><h5 className="footer-link">PG</h5></a></Link>
                    <Link href="/mba"><a><h5 className="footer-link">MBA</h5></a></Link>
                    {/* <Link href="/coaching"><a><h5 className="footer-link">Classroom Coaching</h5></a></Link> */}
                    <a href={process.env.NEXT_PUBLIC_ELEARNING_APP_URI} target="_blank" rel="noreferrer"><h5 className="footer-link">E-Learning</h5></a>
                </div>
                <div className="footer-links-3 text-white">
                    <Link href="/contact"><a><h5 className="footer-link">Contact Us</h5></a></Link>
                    <Link href="/terms"><a><h5 className="footer-link">Terms &amp; Conditions</h5></a></Link>
                    <Link href="/refund"><a><h5 className="footer-link">Refund Policy</h5></a></Link>
                    <Link href="/privacy"><a><h5 className="footer-link">Privacy Policy</h5></a></Link>
                </div>
                <div className="footer-links-social">
                    <h5 className="mb-3">Follow Us</h5>
                    <div className="d-flex justify-content-start justify-content-sm-center justify-content-md-start text-white">
                        <a href="https://www.facebook.com/collegify" target="_blank" rel="noreferrer"><div className="footer-social-circle me-3"><FaFacebookF /></div></a>
                        <a href="https://www.twitter.com/collegify" target="_blank" rel="noreferrer"><div className="footer-social-circle me-3"><FaTwitter /></div></a>
                        <a href="https://www.linkedin.com/company/2692554/" target="_blank" rel="noreferrer"><div className="footer-social-circle"><FaLinkedinIn /></div></a>
                    </div>
                </div>
            </div>

            <hr className="my-3" />

            <div className="footer-copyright text-center">
                <h5 className="mb-0">&copy; Copyright {new Date().getFullYear()} CG Collegify Pvt. Ltd.</h5>
            </div>

        </footer>
    )
}
